import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Solutions from "../components/solutions";

const IndexPage = () => (
    <Layout>
        <SEO title="Mini site - Nos solutions" description={'Personnaliser votre site en ligne'} lang={'fr'}/>
        <main id={'content-page'}>
            <Solutions/>
        </main>
    </Layout>
)

export default IndexPage