const optionsData = {
    options: [
        {
            name: 'Gestion de parc automobile*',
            price: 35,
            priceAsterisk: '**',
            info: 'Dessinez vos parcs - Assigner vos véhicules à une place spécifique Organiser vos rangées et showroom Vérifier en 1 clic où se trouvent vos véhicules'
        },
        {
            name: 'Téléphonie intégrée*',
            info: 'Appelez vos clients directement depuis la fiche client via notre téléphonie intégrée'
        },
        {
            name: 'Gestion des flux vers annonceurs*',
            info: 'Créer vos listes de véhicules et envoyez en 1 clic sur leboncoin, la centrale, auto plus, auto scout24, caroom, ubiflow etc.'
        },
        {
            name: 'Engagement de reprise par E-motors',
            info: 'E-motors s’engage à reprendre les véhicules de vos clients si vous ne souhaitez pas faire la reprise vous-même'
        },
        {
            name: 'Financement pour tous',
            info: 'Faites vos demandes de financement directement sur notre plateforme grâce aux financeurs historiques d’E-motors'
        },
        {
            name: 'Facturation',
            info: 'Gérez facilement vos factures depuis Tetraweb et ne multipliez plus vos différentes solutions de gestion Assigner vos factures, modifier leur statut et suiver les facilement jusqu\'à la régularisation de celles-ci'
        },
        {
            name: 'Messagerie instantanée',
        },
    ],
    asterisk: [
        '*sous réserve d’éligibilité',
        '**35€/mois jusqu’à 50 places puis 2€/places',
    ],
    solutions: {
        title: 'Fonctionnalités à la carte',
        description: [
            '<b>Gestion de parc</b> :\n' +
            'Dessinez facilement vos différents sites de stockages et showroom avec notre outil dédié\n' +
            'Organiser des listes pour vous aider à retrouver tous vos véhicules, mêmes les véhicules de prêts\n' +
            'Mettez à jour vos informations en direct sur votre smartphone',
            '<b>Téléphonie intégrée</b> :\n' +
            'Utilisez notre téléphonie intégrée, les informations du client apparaitront lors de votre appel pour que vous soyez dans les meilleures dispositions \n' +
            'Personnalisez votre message d’attente avec l’aide de notre serveur vocal interactif*\n' +
            'Recevez vos appels sur l’outil de redirigez l’appel vers votre téléphone fixe à tout moment',
            '<b>Messagerie instantanée</b> :\n' +
            'Echangez des informations avec vos collègues directement depuis votre plateforme Tetraweb avec notre chat intégré\n' +
            'Passez des appels visio et organisez vos réunions à distance depuis la même interface'
        ],
        asterisk: [
            '*fonctionnalité supplémentaire, contactez-nous pour en savoir plus',
        ]
    }
};

export default optionsData;
