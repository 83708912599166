import React, {useEffect, useRef, useState} from 'react';
import '../styles/solutions.scss';
import packsData from "../packsData";
import optionsData from "../optionsData";

const Solutions = () => {
    const [packOpen, setPackOpen] = useState(0);
    return (
        <section className="container-solutions">
            <div className="container">
                <h1>Nos solutions</h1>
                {[...Object.values(packsData), optionsData.solutions].filter(p => p.id !== 1).map((pack, index) => (
                    <Solution
                        key={index}
                        pack={pack}
                        index={index}
                        isOpen={packOpen === index}
                        onClick={() => setPackOpen(index)}
                    />
                ))}
            </div>
        </section>
    )
};

const Solution = ({ pack, index, isOpen, onClick = () => {} }) => {
    const ref = useRef(null);
    const [height, setHeight] = useState();
    const [isChecked, setIsChecked] = useState(isOpen);

    useEffect(() => {
        setHeight(ref.current?.scrollHeight);
        function handleResize() {
            setHeight(ref.current?.querySelector('ul').scrollHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setIsChecked(isOpen);
    }, [isOpen]);

    return (
        <div key={pack.title} className={pack.isDisabled ? 'disabled' : ''}>
            <input id={`solution-${index}`} type="radio" name="solution" disabled={pack.isDisabled} value={pack.title} checked={isChecked} onChange={() => onClick()} />
            <label htmlFor={`solution-${index}`}>
                <h3>{pack.title}</h3>
                <span />
            </label>
            <div ref={ref} style={{height: isChecked ? height + 'px' : '0'}}>
                <ul>
                    {pack.description.map((line, indexLine) => (
                        <li key={indexLine} dangerouslySetInnerHTML={{ __html: line}} />
                    ))}
                </ul>
                {pack.asterisk && pack.asterisk.length > 0 && (
                    <ul className="asterisk">
                        {pack.asterisk.map((line, i) => (
                            <li key={i}>{line}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

Solutions.propTypes = {

};

export default Solutions;